<template>
  <div>
    <a-card title="资金流水">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="账户名称/编号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.account" placeholder="结算账户" style="width: 100%" @change="search" >
            <a-select-option v-for="account in accountsItems" :key="account.id" :value="account.id">
              {{ account.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { financeFlowsList } from "@/api/finance";
import { accountsOption } from "@/api/option";

export default {
  name: "SaleRecord",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "创建日期",
          dataIndex: "create_time",
          width: 170,
        },
        {
          title: "结算账户",
          dataIndex: "account_name",
        },
        {
          title: "流水类型",
          dataIndex: "type_display",
        },
        {
          title: "变化之前",
          dataIndex: "amount_before",
        },
        {
          title: "变化金额",
          dataIndex: "amount_change",
        },
        {
          title: "变化之后",
          dataIndex: "amount_after",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 80,
        },
      ],
      searchForm: { page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
      accountsItems: [],
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();

      accountsOption({ page_size: 999999, is_active: true }).then((data) => {
        this.accountsItems = data.results;
      });
    },
    list() {
      this.loading = true;
      financeFlowsList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      }
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    detial(item) {
      this.$router.push({ path: "/finance/flow_detail", query: { item: JSON.stringify(item) } });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
